<template>
    <div class="banner-container">
        <a-card title="banner管理">
            <div class="tool">
                <a-input-search style="width: 250px;" v-model="keyword" placeholder="搜索" enter-button allowClear @search="onSearch" />
                <a-button type="primary" icon @click="add"><a-icon type="plus"></a-icon>新增</a-button>
            </div>
            <div class="table">
                <a-table :loading="loading" :rowKey="(item,i)=>item.id" :columns="columns" :data-source="tableData" :pagination="pagination"></a-table>
            </div>
        </a-card>
        <a-modal :width="800" v-model="editWindowShow" :title="modalTitle" @ok="modalOk" @cancel="modalCancel" cancelText="取消" okText="确定" :confirmLoading="confirmLoading">
            <a-form-model ref="form" :rules="formRules" :model="formData" :label-col="{span:5}" :wrapper-col="{span:12}">
                <a-form-model-item label="轮播图分组名称：" prop="name">
                    <a-input v-model="formData.name" placeholder="请输入名称"></a-input>
                </a-form-model-item>
                <a-form-model-item label="轮播图分组展示至：" prop="type">
                    <a-select v-model="formData.type" placeholder="请选择" allowClear @change="selectChange">
                        <a-select-option v-for="(item,i) in apply" :key="i" :value="item">{{item}}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item v-if="formData.type == '产品服务'" label="展示至产品服务下的：" prop="prodType">
                    <a-select v-model="formData.prodType" placeholder="请选择产品名称" allowClear @change="selectChange">
                        <a-select-option v-for="(item,i) in productSelection" :key="i" :value="item">{{item}}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="简介：" prop="introduce">
                    <a-input type="textarea"  v-model="formData.introduce" placeholder="请输入简介"></a-input>
                </a-form-model-item>
                <a-form-model-item label="上传：" prop="img">
                    <upload ref="upload" upload-length="1" @uploadChange="handleChange" @remove="uploadRemove"></upload>
                </a-form-model-item>
                <a-form-model-item label="轮播时间：" prop="second">
                    <a-input-number v-model="formData.second" placeholder="时间/秒"></a-input-number>
                </a-form-model-item>
                <a-form-model-item label="跳转方式：" prop="model">
                    <a-radio-group :options="plainOptions" v-model="formData.model" @change="radioChange"/>
                </a-form-model-item>
                <a-form-model-item v-if="formData.model == '1'" label="文章选择：" prop="newsLink">
                    <a-select v-model="formData.target" placeholder="请选择" allowClear @change="newsSelectChange">
                        <a-select-option v-for="(item,i) in newsList" :key="i" :value="item.id">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item v-if="formData.model == '2'" label="跳转地址：" prop="link">
                    <a-input v-model="formData.target" placeholder="请输入地址"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import {h} from "vue";
import upload from "@/components/upload.vue";
import {bannerApplyModule, productType, newsRequest, saveOrEditBanner, bannerData,deleteBanner} from "@/api/apis"
import handleRequest from "@/utils/handleRequest";
export default {
    name: "bannerOption",
    components:{upload},
    data() {
        return {
            keyword:'',
            editWindowShow:false,
            confirmLoading:false,
            plainOptions:[
                {
                    label:'无跳转',
                    value:'3',
                },
                {
                    label:'站内跳转',
                    value:'1',
                },
                {
                    label:'站外跳转',
                    value:'2',
                },
            ],
            modalTitle:'新增',
            apply:[],
            productSelection:[],
            newsList:[],
            formData:{
                introduce:'',
                sort:'',
                name:'',
                type:'',
                prodType:'',
                img:[],
                second:'',
                model:'3',
                target:'',
            },

            formRules: {
                name: [{required:false,message:'请输入名称',trigger:'blur'}],
                type: [{required:true,message:'请选择展示的页面',trigger:'blur'}],
                img: [{validator: (rule,value,callback)=>{
                        if(!this.formData.img.length){
                            callback('请至少上传一张')
                        }else{
                            return true
                        }
                    },trigger:'blur'}],
                second:[{required:true,message:'请输入持续时间',trigger:'blur'}],
                prodType:[{required:true,message:'请选择展示的产品页面',trigger:'blur'}]
            },
            pagination:{
                pageSize:10,
                current:1,
                total:0,
                showTotal:(total)=>`共${total}条`,
                onChange:(page,pageSize)=>this.pageChange(page,pageSize)
            },
            loading:false,
            columns:[
                {
                    title: '序号',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    customRender: (text,record,index) => `${index+1}`,
                },
                {
                    align: 'center',
                    dataIndex:'name',
                    key:'name',
                    title:'名称',
                },
                {
                    align: 'center',
                    dataIndex:'bannerLength',
                    key:'bannerLength',
                    title:'轮播图数量',
                },
                {
                    align: 'center',
                    dataIndex:'second',
                    key:'second',
                    title:'轮播时间',
                    customRender:(text, record, index)=> `${record.second}s`
                },
                {
                    align: 'center',
                    dataIndex:'state',
                    key:'state',
                    title:'状态',
                    customRender:(text, record, index)=> {
                        return  h("a-switch",{
                            props:{
                                checked:record.state == 1 ? true:false
                            },
                            on:{
                                'change':()=>{
                                    this.switchChange(record)
                                }
                            }
                        })
                    }
                },
                {
                    align: 'center',
                    dataIndex:'action',
                    key:'action',
                    title:'操作',
                    width:200,
                    customRender:(text,record,index)=>{
                        return h("div",[
                            h("a-button", {
                                 style:{
                                     marginRight:'10px'
                                 },
                                 props:{
                                     icon:'edit',
                                     type:'primary',
                                     size:'small'
                                 },
                                 on:{
                                     click:()=>{
                                        this.edit(record)
                                     }
                                 }
                             }, '编辑'),
                            h("a-popconfirm",{
                                props:{
                                    title:'确定要删除吗？',
                                    okText:'确定',
                                    cancelText:'取消',
                                },
                                on:{
                                    'confirm':()=>{
                                        this.delete(record)
                                    }
                                }
                            },[
                                h("a-button",{
                                    props:{
                                        icon:'delete',
                                        type: 'danger',
                                        size:'small',
                                    }
                                },'删除')
                            ])
                        ])
                    }
                },
            ],
            tableData:[],
        }
    },
    mounted() {
        this.getBannerApplyModule();
        this.getTableData();
    },
    methods: {
        async switchChange(params){
            params.state = params.state == 1 ? false:true
            let switchedData = {...params}
            switchedData.img = JSON.stringify(switchedData.img)
            switchedData.state = switchedData.state == true ? 1:2
            const res = await saveOrEditBanner(switchedData)
            handleRequest(res,(res)=>{
                this.$message.success('切换成功')
                this.getTableData()
            })
        },
        pageChange(page,pageSize){
            this.pagination.current = page
            this.getTableData()
        },
        async getTableData(){
            this.loading = true
            let params = {
                pageSize: this.pagination.pageSize,
                page:this.pagination.current,
                name:this.keyword,
            }
            const res = await bannerData(params)
            const data = handleRequest(res)
            this.pagination.total = data.totalElements
            this.pagination.current = data.number+1
            this.tableData = data.content
            this.tableData.forEach(item=>{
                item.img = JSON.parse(item.img)
                item.bannerLength = item.img?.length
            })
            this.loading = false
        },
        async getBannerApplyModule(){
            const res = await bannerApplyModule()
            try {
                if(res?.code == 200){
                    this.apply = res.data
                }else{
                    this.message.error(res.msg)
                }
            }catch (e) {
                throw e
                this.message.error(e)
            }
        },
        uploadRemove(list){
            this.formData.img = list
        },
        handleChange(list){
            this.formData.img = list
        },
        onSearch(){
            this.getTableData()
        },
        modalCancel(){
            this.getTableData()
        },
        modalOk(){
            console.log('编辑窗口确定')
            this.confirmLoading = true
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.formData.second = Math.abs(this.formData.second)
                    this.formData.sort = this.tableData.length+1
                    this.formData.img = JSON.stringify(this.formData.img)
                    this.formData.name = this.formData.name.trim()
                    const res = await saveOrEditBanner(this.formData)
                    handleRequest(res,(res)=>{
                        if(res.code == 200){
                            this.$message.success('操作成功')
                            this.confirmLoading = false
                            this.editWindowShow = false
                            this.getTableData()
                        }
                    })
                } else {
                    console.log('验证错误');
                    console.log(this.formData)
                    this.confirmLoading = false
                    return false;
                }
            });
        },
        //TODO 由于外部链接和文章id都存在一个字段上，无法分开保存，只能覆盖
        async radioChange({target}){
            let value = target.value
            if(value == '1'){
                const res = await newsRequest({state:1})
                const data = handleRequest(res)
                this.newsList = data
            }
        },
        newsSelectChange(value){
            console.log(value)
        },
        async selectChange(value){
            if(value =='产品服务'){
                try {
                    const res = await productType()
                    if(res?.code == 200){
                       this.productSelection = res.data
                    }else{
                        this.message.error(res.msg)
                    }
                }catch (e) {
                    throw e
                    this.message.error(e)
                }
            }
        },
        add(){
            this.editWindowShow = true
            this.modalTitle = '新增'
            this.formData = {
                introduce:'',
                sort:'',
                name:'',
                type:'',
                prodType:'',
                img:[],
                second:'',
                model:'3',
                target:'',
            }
            this.$nextTick(()=>{
                this.$refs.upload.imgList = []
            })
        },
        async edit(params){
            this.editWindowShow = true
            this.modalTitle = '编辑'
            this.formData = params
            let model = {
                target:{value:this.formData.model}
            }
            await this.radioChange(model)
            this.$nextTick(()=>{
                this.$refs.upload.imgList = this.formData.img
            })
            try {
                const res = await productType()
                if(res?.code == 200){
                    this.productSelection = res.data
                }else{
                    this.message.error(res.msg)
                }
            }catch (e) {
                throw e
                this.message.error(e)
            }
        },
        async delete(params){
            console.log(params)
            const res = await deleteBanner({id:params.id})
            handleRequest(res,(res)=>{
                if(res){
                    this.$message.success('删除成功')
                    this.getTableData();
                }
            })
        }
    },
}
</script>

<style scoped lang="scss">
.banner-container{
    padding: 20px;
        .tool{
            display: flex !important;
            justify-content: space-between;
            align-items: center;
        }
    .table{
        margin-top: 30px;
    }
}
</style>
